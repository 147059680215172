
// @ts-nocheck


export const localeCodes =  [
  "nl",
  "en",
  "de"
]

export const localeLoaders = {
  "nl": [{ key: "../../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compile_rahmyeam73jg2acujflb6nxujy/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json", load: () => import("../../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compile_rahmyeam73jg2acujflb6nxujy/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json" /* webpackChunkName: "locale__data_www_groentenfruithuis_nl_gfh_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_base_641_0_0_rc_4__64nuxt_43devtools_641_0_8__64unhead_43shared_641_8_11__64vue_43compile_rahmyeam73jg2acujflb6nxujy_node_modules__64bttr_devs_nuxt_layer_base_locales_nl_json" */), cache: true },
{ key: "../../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compil_vvhptpjmsqspcllg5hmmn5tcnu/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json", load: () => import("../../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compil_vvhptpjmsqspcllg5hmmn5tcnu/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json" /* webpackChunkName: "locale__data_www_groentenfruithuis_nl_gfh_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_sofie_641_0_0_rc_4__64nuxt_43devtools_641_0_8__64unhead_43shared_641_8_11__64vue_43compil_vvhptpjmsqspcllg5hmmn5tcnu_node_modules__64bttr_devs_nuxt_layer_sofie_locales_nl_json" */), cache: true },
{ key: "../translations/nl-NL.json", load: () => import("../translations/nl-NL.json" /* webpackChunkName: "locale__data_www_groentenfruithuis_nl_gfh_nuxt_website_translations_nl_NL_json" */), cache: true }],
  "en": [{ key: "../../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compile_rahmyeam73jg2acujflb6nxujy/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json", load: () => import("../../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compile_rahmyeam73jg2acujflb6nxujy/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json" /* webpackChunkName: "locale__data_www_groentenfruithuis_nl_gfh_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_base_641_0_0_rc_4__64nuxt_43devtools_641_0_8__64unhead_43shared_641_8_11__64vue_43compile_rahmyeam73jg2acujflb6nxujy_node_modules__64bttr_devs_nuxt_layer_base_locales_en_json" */), cache: true },
{ key: "../../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compil_vvhptpjmsqspcllg5hmmn5tcnu/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json", load: () => import("../../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compil_vvhptpjmsqspcllg5hmmn5tcnu/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json" /* webpackChunkName: "locale__data_www_groentenfruithuis_nl_gfh_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_sofie_641_0_0_rc_4__64nuxt_43devtools_641_0_8__64unhead_43shared_641_8_11__64vue_43compil_vvhptpjmsqspcllg5hmmn5tcnu_node_modules__64bttr_devs_nuxt_layer_sofie_locales_en_json" */), cache: true },
{ key: "../translations/en-US.json", load: () => import("../translations/en-US.json" /* webpackChunkName: "locale__data_www_groentenfruithuis_nl_gfh_nuxt_website_translations_en_US_json" */), cache: true }],
  "de": [{ key: "../translations/de-DE.json", load: () => import("../translations/de-DE.json" /* webpackChunkName: "locale__data_www_groentenfruithuis_nl_gfh_nuxt_website_translations_de_DE_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "nl",
      "domain": "https://groentenfruithuis.nl",
      "title": "Nederlands",
      "files": [
        "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compile_rahmyeam73jg2acujflb6nxujy/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json",
        "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compil_vvhptpjmsqspcllg5hmmn5tcnu/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json",
        "translations/nl-NL.json"
      ]
    },
    {
      "code": "en",
      "domain": "https://accept.freshproducecentre.com",
      "title": "English",
      "files": [
        "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compile_rahmyeam73jg2acujflb6nxujy/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json",
        "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compil_vvhptpjmsqspcllg5hmmn5tcnu/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json",
        "translations/en-US.json"
      ]
    },
    {
      "domain": "https://accept.obstgemusehaus.de",
      "code": "de",
      "title": "Deutsch",
      "files": [
        "translations/de-DE.json"
      ]
    }
  ],
  "defaultLocale": "nl",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": true,
  "langDir": "translations",
  "rootRedirect": null,
  "detectBrowserLanguage": false,
  "differentDomains": true,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "about-us/index": {
      "nl": "/over-ons",
      "en": "/about-us",
      "de": "/uber-uns"
    },
    "about-us/medewerkers": {
      "nl": "/over-ons/medewerkers",
      "en": "/about-us/employees",
      "de": "/uber-uns/personal"
    },
    "about-us/business-councils/index": {
      "nl": "/over-ons/business-councils",
      "en": "/about-us/business-councils",
      "de": "/uber-uns/unternehmensrate"
    },
    "about-us/business-councils/[slug]": {
      "nl": "/over-ons/business-councils/[slug]",
      "en": "/about-us/business-councils/[slug]",
      "de": "/uber-uns/unternehmensrate/[slug]"
    },
    "about-us/committees/index": {
      "nl": "/over-ons/comites",
      "en": "/about-us/committees",
      "de": "/uber-uns/ausschüsse"
    },
    "about-us/committees/[slug]": {
      "nl": "/over-ons/comites/[slug]",
      "en": "/about-us/committees/[slug]",
      "de": "/uber-uns/ausschüsse/[slug]"
    },
    "about-us/networking-clubs/index": {
      "nl": "/over-ons/netwerkclubs",
      "en": "/about-us/networking-clubs",
      "de": "/uber-uns/networking-clubs"
    },
    "about-us/networking-clubs/[slug]": {
      "nl": "/over-ons/netwerkclubs/[slug]",
      "en": "/about-us/networking-clubs/[slug]",
      "de": "/uber-uns/networking-clubs/[slug]"
    },
    "about-us/networking-groups/index": {
      "nl": "/over-ons/netwerkgroepen",
      "en": "/about-us/networking-groups",
      "de": "/uber-uns/netzwerkgruppen"
    },
    "about-us/networking-groups/[slug]": {
      "nl": "/over-ons/netwerkgroepen/[slug]",
      "en": "/about-us/networking-groups/[slug]",
      "de": "/uber-uns/netzwerkgruppen/[slug]"
    },
    "about-us/vacancies/[slug]": {
      "nl": "/over-ons/vacatures/[slug]",
      "en": "/about-us/vacancies/[slug]",
      "de": "/uber-uns/Stellenangebote/[slug]"
    },
    "ambitions/index": {
      "nl": "/ambities",
      "en": "/ambitions",
      "de": "/ambitionen"
    },
    "ambitions/[ambition]/index": {
      "nl": "/ambities/[ambition]",
      "en": "/ambitions/[ambition]",
      "de": "/ambitionen/[ambition]"
    },
    "ambitions/[ambition]/[slug]": {
      "nl": "/ambities/[ambition]/[slug]",
      "en": "/ambitions/[ambition]/[slug]",
      "de": "/ambitionen/[ambition]/[slug]"
    },
    "members/index": {
      "nl": "/leden",
      "en": "/members",
      "de": "/mitglieder"
    },
    "members/[slug]": {
      "nl": "/leden/[slug]",
      "en": "/members/[slug]",
      "de": "/mitglieder/[slug]"
    },
    "news/index": {
      "nl": "/nieuws",
      "en": "/news",
      "de": "/nachrichten"
    },
    "news/[slug]": {
      "nl": "/nieuws/[slug]",
      "en": "/news/[slug]",
      "de": "/nachrichten/[slug]"
    },
    "contact": {
      "nl": "/contact",
      "en": "/contact",
      "de": "/kontakt"
    },
    "events/index": {
      "nl": "/agenda",
      "en": "/events",
      "de": "/ereignisse"
    },
    "events/[slug]": {
      "nl": "/agenda/[slug]",
      "en": "/events/[slug]",
      "de": "/veranstaltungen/[slug]"
    },
    "search": {
      "nl": "/zoeken",
      "en": "/search",
      "de": "/süchen"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "fallbackLocale": "en",
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const normalizedLocales = [
  {
    "code": "nl",
    "domain": "https://groentenfruithuis.nl",
    "title": "Nederlands",
    "files": [
      {
        "path": "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compile_rahmyeam73jg2acujflb6nxujy/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json"
      },
      {
        "path": "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compil_vvhptpjmsqspcllg5hmmn5tcnu/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json"
      },
      {
        "path": "translations/nl-NL.json"
      }
    ]
  },
  {
    "code": "en",
    "domain": "https://accept.freshproducecentre.com",
    "title": "English",
    "files": [
      {
        "path": "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compile_rahmyeam73jg2acujflb6nxujy/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json"
      },
      {
        "path": "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compil_vvhptpjmsqspcllg5hmmn5tcnu/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json"
      },
      {
        "path": "translations/en-US.json"
      }
    ]
  },
  {
    "domain": "https://accept.obstgemusehaus.de",
    "code": "de",
    "title": "Deutsch",
    "files": [
      {
        "path": "translations/de-DE.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const STRATEGIES = {
  "PREFIX": "prefix",
  "PREFIX_EXCEPT_DEFAULT": "prefix_except_default",
  "PREFIX_AND_DEFAULT": "prefix_and_default",
  "NO_PREFIX": "no_prefix"
}
export const DEFAULT_LOCALE = ""
export const DEFAULT_STRATEGY = "prefix_except_default"
export const DEFAULT_TRAILING_SLASH = false
export const DEFAULT_ROUTES_NAME_SEPARATOR = "___"
export const DEFAULT_LOCALE_ROUTE_NAME_SUFFIX = "default"
export const DEFAULT_DETECTION_DIRECTION = "ltr"
export const DEFAULT_BASE_URL = ""
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"


