import {enableBodyScroll, disableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('body-scroll-lock', {
    mounted(el, binding) {
      if (binding.value) {
        disableBodyScroll(el);
      }
    },

    updated(el, binding) {
      if (binding.value) {
        disableBodyScroll(el);
      } else {
        enableBodyScroll(el);
      }
    },

    unmounted(el) {
      clearAllBodyScrollLocks(el);
    },
  });
});
