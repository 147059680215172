import menuQuery from '~/graphql/queries/menus.gql';
import {Menu, MenuItem} from 'graphql/graphql';

interface Menus {
  header: MenuItem[]
  footer: MenuItem[]
}

const getQueryName = (name: string, locale: string) => locale === 'nl' ? name : `${name} ${locale}`;
const getMenuFromData = (menus: Menu[], name: string) => (menus?.find(m => m.name === name)?.items ?? []) as MenuItem[];

export const useMenu = () => {
  const nuxtApp = useNuxtApp();
  const {resolveClient} = useApolloClient();
  const client = resolveClient();

  const menus = useState<Menus>('menus', () => ({
    header: [],
    footer: [],
  }));

  const refetchMenus = async () => {
    const locale = nuxtApp.$i18n.locale.value;
    const headerMenu = getQueryName('hoofdmenu', locale);
    const footerMenu = getQueryName('footer 1', locale);

    const result = await client.query({
      query: menuQuery,
      variables: {
        names: [headerMenu, footerMenu],
      },
    });

    menus.value = {
      header: getMenuFromData(result.data.menus ?? [], headerMenu),
      footer: getMenuFromData(result.data.menus ?? [], footerMenu),
    };
  };

  return {
    menus,
    refetchMenus,
  };
};
