<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <Notifications
      position="top center"
      :duration="2000"
    >
      <template #body="{item, close}">
        <div class="m-2.5">
          <button
            class="btn btn-purple w-full rounded-lg"
            @click="close()"
          >
            <p class="text-sm">
              {{ item.title }}
            </p>
          </button>
        </div>
      </template>
    </Notifications>
  </div>
</template>

<script setup lang="ts">
const page = useSofiePageWhenAvailable();
const route = useRoute();
const {locale} = useI18n();

useHead({
  title: page.value?.title ?? route.meta?.title ?? '',
  titleTemplate: (titleChunk) => {
    const siteName = {
      en: 'Fresh Produce Centre',
      de: 'Obst Gemüse Haus',
    }[locale.value] ?? 'GroentenFruit Huis';

    return titleChunk ? `${titleChunk} - ${siteName}` : siteName;
  },
  link: [
    {
      rel: 'stylesheet',
      href: 'https://fonts.bunny.net/css2?family=Ubuntu:wght@300;400;500;700&display=swap',
    },
  ],
  meta: [
    page.value?.seo?.meta_description
      ? {
        hid: 'description',
        name: 'description',
        content: page.value.seo.meta_description,
      }
      : {},
    page.value?.seo?.meta_index
      ? {
        hid: 'robots',
        name: 'robots',
        content:
          (page.value.seo.meta_follow ? 'follow ' : 'nofollow ') +
          (page.value.seo.meta_index ? 'index' : 'noindex'),
      }
      : {},
    page.value?.seo?.meta_title
      ? {
        hid: 'og:title',
        name: 'og:title',
        content: page.value.seo.meta_title,
      }
      : {},
    page.value?.seo?.meta_description
      ? {
        hid: 'og:description',
        name: 'og:description',
        content: page.value.seo.meta_description,
      }
      : {},
  ],
});
</script>
