import {useApolloClient} from '@vue/apollo-composable';

export default defineNuxtPlugin((nuxtApp) => {
  const {resolveClient} = useApolloClient();
  const client = resolveClient();
  const menu = useMenu();

  nuxtApp.hook('apollo:headers', (headers) => {
    headers.value = {
      ...headers.value,
      'X-Locale': nuxtApp.$i18n.locale.value,
    };
  });

  nuxtApp.$i18n.onLanguageSwitched = async () => {
    await menu.refetchMenus();
    await client.resetStore();
  };

  nuxtApp.hook('app:created', async () => {
    await menu.refetchMenus();
  });
});
