import revive_payload_client_QBwCckm5Kn from "/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_stylelint@16.2.1_typescript@5.3.3_vite@5.1.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_T2TOIXbbR4 from "/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_stylelint@16.2.1_typescript@5.3.3_vite@5.1.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_EkxLzFCk8T from "/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_stylelint@16.2.1_typescript@5.3.3_vite@5.1.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_rfNdHAGQij from "/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.11_@nuxt+devtools@1.0.8_@vue+compiler-core@3.4.21_nuxt@3.10.3_postcss@8._hs453aanh4u6c57ibt5wenhwz4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_B1xn8tZ7DS from "/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_stylelint@16.2.1_typescript@5.3.3_vite@5.1.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_TINKtHYJAc from "/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_stylelint@16.2.1_typescript@5.3.3_vite@5.1.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/.nuxt/components.plugin.mjs";
import prefetch_client_4htotyspCW from "/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_stylelint@16.2.1_typescript@5.3.3_vite@5.1.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_mA9A3T2uMW from "/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.2.3_rollup@4.12.1/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import i18n_NlWL7vXNtg from "/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_rollup@4.12.1_vue@3.4.21/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/.nuxt/formkitPlugin.mjs";
import plugin_BFpWinlBRx from "/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.0.0-rc.4_react-dom@18.2.0_react@18.2.0_rollup@4.12.1_typescript@5.3.3_vue@3.4.21/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_CK3RvkdGHS from "/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_stylelint@16.2.1_typescript@5.3.3_vite@5.1.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import errors_prZel3WYv8 from "/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compile_rahmyeam73jg2acujflb6nxujy/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_7ubKKB2QUZ from "/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compile_rahmyeam73jg2acujflb6nxujy/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_KbFrlr1Eum from "/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compil_vvhptpjmsqspcllg5hmmn5tcnu/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import gtm_client_OzmBuHLRIb from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/plugins/gtm.client.ts";
import i18n_VfGcjrvSkj from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/plugins/i18n.ts";
import notifications_Wskzy5ZO71 from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/plugins/notifications.ts";
import scrollLock_SmIYaqToSJ from "/data/www/groentenfruithuis.nl/gfh/nuxt/website/plugins/scrollLock.ts";
export default [
  revive_payload_client_QBwCckm5Kn,
  unhead_T2TOIXbbR4,
  router_EkxLzFCk8T,
  _0_siteConfig_rfNdHAGQij,
  payload_client_B1xn8tZ7DS,
  check_outdated_build_client_TINKtHYJAc,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4htotyspCW,
  plugin_mA9A3T2uMW,
  i18n_NlWL7vXNtg,
  formkitPlugin_pZqjah0RUG,
  plugin_BFpWinlBRx,
  chunk_reload_client_CK3RvkdGHS,
  errors_prZel3WYv8,
  i18n_7ubKKB2QUZ,
  apollo_KbFrlr1Eum,
  gtm_client_OzmBuHLRIb,
  i18n_VfGcjrvSkj,
  notifications_Wskzy5ZO71,
  scrollLock_SmIYaqToSJ
]