import validate from "/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_stylelint@16.2.1_typescript@5.3.3_vite@5.1.5/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compile_rahmyeam73jg2acujflb6nxujy/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_rollup@4.12.1_stylelint@16.2.1_typescript@5.3.3_vite@5.1.5/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.0-rc.4_@nuxt+devtools@1.0.8_@unhead+shared@1.8.11_@vue+compil_vvhptpjmsqspcllg5hmmn5tcnu/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts"),
  page: () => import("/data/www/groentenfruithuis.nl/gfh/nuxt/website/middleware/page.ts")
}