<template>
  <NuxtLayout>
    <div class="page-offset container pb-5 lg:pb-10">
      <h1 class="heading">
        {{ error.statusCode }} - {{ error.statusCode == 404 ? `${$t('Page not found')}.` : error.message }}
      </h1>
      <p>{{ $t('Error occurred') }}.</p>
      <Btn
        :label="$t('Go back')"
        type="button"
        @click="handleError"
      />
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import type {NuxtError} from '#app';

defineProps<{
  error: NuxtError,
}>();

const handleError = () => useRouter().go(-1);
</script>
